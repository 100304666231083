@font-face {
  font-family: "MacPaw";
  src: url(./fonts/MacPawFixel-VF.ttf);
  font-weight: lighter;
}

@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-BoldItalic.ttf);
  font-weight: "BoldItalic";
}
@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-Italic.ttf);
  font-weight: "Italic";
}
@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-Light.ttf);
  font-weight: "Light";
}
@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-LightItalic.ttf);
  font-weight: "LightItalic";
}
@font-face {
  font-family: "Sandro";
  src: url(./fonts/SandroGrottesco-Regular.ttf);
  font-weight: "Regular";
}
body {
  margin: auto;
  /* background-image: url(../src/images/Bg.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-attachment: fixed; */
  overflow-x: hidden;
  background-color: #000 !important;
}

.pictureMove {
  animation: move 20s infinite linear;
  position: absolute;
  z-index: -1;
}

@keyframes move {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}
.MuiPaper-root {
  /* background-color: #02052c !important; */
}
.MuiDialogContent-root {
  padding: 0% !important;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  height: auto !important;
}
.modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #1d3463;
}
.modalItemsScroll::-webkit-scrollbar-thumb {
  background: #02052c;
  border-radius: 20px;
}
.MuiSvgIcon-root {
  color: #707e9b;
}

.eth_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
  padding-bottom: 30px;
}
.eth_modalItemsScroll::-webkit-scrollbar {
  width: 8px;
}
.eth_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 5px;
  box-shadow: inset 0 0 6px #d2dfff;
}
.eth_modalItemsScroll::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.11);
  border-radius: 5px;
}
.pol_modalItemsScroll {
  overflow-y: scroll;
  height: 290px;
}
.pol_modalItemsScroll::-webkit-scrollbar {
  width: 15px;
}
.pol_modalItemsScroll::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 20px;
  box-shadow: inset 0 0 6px #02052c;
}
.pol_modalItemsScroll::-webkit-scrollbar-thumb {
  background: #ed2196;
  border-radius: 20px;
}
.MuiButton-root:hover {
  text-decoration: none;
  /* background-color: #fff !important; */
}

.MuiDialog-paperScrollPaper {
  background: #ed2196;
  width: auto;
  box-shadow: 0px 4px 4px rgba(107, 107, 107, 0.25);
  border-radius: 30px !important;
  /* padding: 4px; */
  z-index: 10000000 !important;
}
.dialoge__content__section {
  background: #212535 !important;
  background-color: #212535 !important;

  padding: 20px !important;
  border-radius: 30px;
  width: 100%;
  height: 100%;
  z-index: 10000000 !important;
}

@media screen and (max-width: 544px) {
  .MuiDialog-paperScrollPaper {
    width: 100%;
  }
}

#token-search-input {
  color: #fff !important;
}
